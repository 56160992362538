import React, { FC, ReactNode } from "react";
import {
  StyledHeading1,
  StyledHeading2,
  StyledHeading3,
  StyledHeading4,
  StyledHeading5,
  StyledHeading6,
} from "./index.styled";

export interface HeadingStyleProps {
  size: HeadingSize;
  // this overrides the color set in the theme, use sparingly!
  color?: string;
  center?: boolean;
}

interface HeadingProps extends HeadingStyleProps {
  level: HeadingLevel;
  children: ReactNode;
  // https://styled-components.com/docs/advanced#styling-normal-react-components
  className?: string;
  dataTestId?: string;
}

/**
 * Heading size refers to the defined size/font/color etc.
 * in the theme and is unrelated to the heading level.
 */
type HeadingSize = "xxl" | "xl" | "l" | "m" | "s" | "xs" | "xxs";

/**
 * Heading levels (<h1>, <h2>, etc) are not simply about sizing
 * and styling of header text, they provide semantic information
 * about the organization and importance of the content. They
 * are also interpreted by screen readers so that users can
 * jump directly to top level headings, next level headings,
 * and so on.
 *
 * In this component, we override all default styles of the
 * heading levels with the definitions from the heading size.
 */
type HeadingLevel = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

const HeadingComponents = {
  h1: StyledHeading1,
  h2: StyledHeading2,
  h3: StyledHeading3,
  h4: StyledHeading4,
  h5: StyledHeading5,
  h6: StyledHeading6,
};

export const Heading: FC<HeadingProps> = ({
  size,
  level,
  children,
  color,
  center,
  className,
  dataTestId,
}) => {
  const HeadingComponent = HeadingComponents[level];
  return (
    <HeadingComponent
      size={size}
      color={color}
      center={center}
      className={className || ""}
      data-testid={dataTestId}
    >
      {children}
    </HeadingComponent>
  );
};
