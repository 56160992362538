import styled from "styled-components";

export const StyledHeader = styled.div<{ type: "checkout" | "portal" }>`
  background-color: ${({ theme, type }) => {
    let bgColor;
    if (type === "checkout") {
      bgColor = theme.header?.simple?.checkout?.backgroundColor;
    } else if (type === "portal") {
      bgColor = theme.header?.simple?.portal?.backgroundColor;
    }
    return bgColor || theme.color.primary;
  }};
  height: 60px;
  min-height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledImage = styled.img`
  height: 50px;
  width: 180px;
  object-fit: contain;
`;
