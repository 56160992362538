import React, { FC, ReactNode, useContext } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { TenantContext } from "v3/context/tenant";
import { LocalizationContext } from "v3/context/localization";
import { SimpleHeader } from "@pepdirect/v3/ui/SimpleHeader";
import { LoadingPage } from "v3/components/LoadingPage";
import {
  BrandConfigCustomizationsImage,
  useCurrentUserIdQuery,
} from "v3/services/graphql/generated";
import { PageRoutes } from "v3/constants/pageRoutes";

interface PortalPublicPageWrapperProps {
  children: ReactNode;
  title: string;
}

/**
 * Used on pages in portal where a user is not logged in
 */
export const PortalPublicPageWrapper: FC<PortalPublicPageWrapperProps> = ({
  children,
  title,
}) => {
  const { tenant } = useContext(TenantContext);
  const { localization } = useContext(LocalizationContext);
  const { shopLogoAltText = "shop logo" } = localization?.portal?.alts || {};
  const fallbackUrl = tenant?.fallbackUrl || "";
  const logo =
    tenant?.brandConfig?.customizations?.images?.find(
      (img: BrandConfigCustomizationsImage) => img.name === "logoSmallUrl"
    ) || null;
  const accountIconUrl = tenant?.brandConfig?.customizations?.images?.find(
    (img: BrandConfigCustomizationsImage) => img.name === "accountIconUrl"
  );
  const router = useRouter();
  const { data: currentUserId, loading: currentUserIdLoading } =
    useCurrentUserIdQuery();

  if (currentUserIdLoading) return <LoadingPage />;

  if (currentUserId?.currentUser) {
    router.push(PageRoutes.account);
    return null;
  }

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      {!accountIconUrl && (
        <SimpleHeader
          fallbackUrl={fallbackUrl}
          logo={{ src: logo?.src || "", alt: logo?.alt || shopLogoAltText }}
          type="portal"
        />
      )}
      {children}
    </>
  );
};
