import React, { FC } from "react";
import { InternalLink } from "../../links/InternalLink";
import { StyledHeader, StyledImage } from "./index.styled";

interface SimpleHeaderProps {
  fallbackUrl: string | null;
  logo: { src: string; alt: string | null } | null;
  type: "checkout" | "portal";
}

export const SimpleHeader: FC<SimpleHeaderProps> = ({
  fallbackUrl,
  logo,
  type,
}) => {
  if (
    typeof window !== "undefined" &&
    window.navigator.userAgent.includes("GxApp")
  ) {
    return null;
  }

  return (
    <StyledHeader type={type}>
      <InternalLink href={fallbackUrl || ""} center>
        <StyledImage alt={logo?.alt || ""} src={logo?.src || ""} />
      </InternalLink>
    </StyledHeader>
  );
};
